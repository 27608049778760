import languageStore from "@reservauto/react-shared/stores/languageStore";
import useStateStore from "@reservauto/react-shared/stores/useStateStore";
import UrlParams from "@reservauto/react-shared/UrlParams";
import { addHours, format as formatDate } from "date-fns";
import featuresInfo, { FeatureType } from "../../frame/featuresInfo";
import { EAvailableFeatureType } from "../general/dto";

export type LegacyTypes = "legacy" | "mvc";

export const isInIframeParamName = "ReactIframe";
export const isInIframeParam = `${isInIframeParamName}=true`;

export const legacyUrlRoot = "/legacy";

export const legacyUrlParams = {
  featureType: "id",
  title: "title",
  type: "type",
  url: "url",
};

export function useCustomerRoute(customerId: number): string {
  const language = useStateStore(languageStore);
  const urlAction = language.twoLetterCode === "fr" ? "Modifier" : "Modify";

  return createLegacyRoute(
    "legacy",
    `/Scripts/Gestion/CustomerManager.asp?Action=${urlAction}&CustomerID=${customerId.toString()}`,
  );
}

export function useCompanyRoute(
  companyId: number | null | undefined,
): string | null {
  const language = useStateStore(languageStore);
  const urlAction = language.twoLetterCode === "fr" ? "Modifier" : "Modify";

  if (typeof companyId !== "number") {
    return null;
  }

  return createLegacyRoute(
    "legacy",
    `/Scripts/Gestion/CustomerManager.asp?Action=${urlAction}&CompanyID=${companyId.toString()}`,
  );
}

export function useReservationGridRoute(
  cityId: number | null | undefined,
  vehicleNo: number | null | undefined,
  date: Date,
  stationId?: number | null,
): string {
  const urlDateFormat = "dd/MM/yyyy HH:mm:ss";
  const adminCommunautoCustomerId = 108;

  return createLegacyRoute(
    "legacy",
    "/Scripts/Gestion/DisplayReservation.asp?" +
      (cityId ? `lngCodeVille=${cityId.toString()}&` : "") +
      `CustomerID=${adminCommunautoCustomerId.toString()}` +
      (vehicleNo ? `&NoVehicule=${vehicleNo.toString()}` : "") +
      (stationId ? `&strChoixStation=${stationId.toString()}` : "") +
      `&strDateDebut=${formatDate(date, urlDateFormat)}` +
      `&strDateFin=${formatDate(addHours(date, 24), urlDateFormat)}`,
  );
}

export function useVehicleInfoRoute(
  cityId: number | null,
  vehicleId: number,
  vehicleNo: number,
  stationId: number,
): string {
  return createLegacyRoute(
    "legacy",
    "/Scripts/Gestion/VehiculeMAJ.asp?Txs=Mod" +
      (cityId ? `&intVilleUtilisation=${cityId.toString()}` : "") +
      `&ST=${stationId.toString()}` +
      `&strNoVehiculeCA=${vehicleNo.toString()}` +
      `&CarID=${vehicleId.toString()}`,
  );
}

export function createLegacyRoute(
  type: LegacyTypes,
  url: string,
  title = "",
): string {
  const urlParams = new URLSearchParams();
  urlParams.append(legacyUrlParams.type, type);
  urlParams.append(legacyUrlParams.url, url);
  if (title) {
    urlParams.append(legacyUrlParams.title, title);
  }

  return legacyUrlRoot + "?" + urlParams.toString();
}

export function createFeatureLegacyRoute(
  featureType: EAvailableFeatureType,
): string {
  const urlParams = new URLSearchParams();
  urlParams.append(legacyUrlParams.featureType, featureType);

  return legacyUrlRoot + "?" + urlParams.toString();
}

export function isLegacyRoute(type?: LegacyTypes): boolean {
  const legacyUrls = ["/", legacyUrlRoot];
  if (!legacyUrls.includes(window.location.pathname.toLowerCase())) {
    return false;
  }

  if (type !== undefined) {
    if (window.location.pathname === "/") {
      return type === "mvc";
    }

    const params = new UrlParams();
    const featureType = params.get(legacyUrlParams.featureType);
    if (featureType) {
      const featureInfo = featuresInfo.find(
        (f) => f.type === (featureType as FeatureType),
      );
      if (featureInfo?.legacyType !== type) {
        return false;
      }
    } else if (params.get(legacyUrlParams.type) !== type) {
      return false;
    }
  }

  return true;
}
