/* eslint-disable no-restricted-syntax */
import UserManagerWithMetadataCache from "@reservauto/react-shared/frame/UserManagerWithMetadataCache";
import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import Logging from "@reservauto/react-shared/Logging";
import { NetworkRequestFailedError } from "@reservauto/react-shared/services/ServiceBase";
import languageStore from "@reservauto/react-shared/stores/languageStore";
import renewingSessionStore from "@reservauto/react-shared/stores/renewingSessionStore";
import {
  add as addDate,
  differenceInSeconds,
  minutesToMilliseconds,
} from "date-fns";
import {
  Log,
  SignoutResponse,
  User,
  WebStorageStateStore,
} from "oidc-client-ts";
import {
  expireLegacySessions,
  loginToLegacy,
} from "../areas/legacy/authenticationServices";
import { isLegacyRoute } from "../areas/legacy/legacyRoute";
import appSettings from "../shared/appSettings";
import userBOStore from "../shared/stores/userBOStore";

interface RenewSessionTries {
  count: 0;
  lastRenewTime?: Date;
}

const lastLoginTimeStoreKey: StorageKeyInfo<number | null> = {
  key: "Auth_LastLoginTime",
  pathIndependant: true,
  userIndependant: true,
};
const lastNewRefreshTokenTimeStoreKey: StorageKeyInfo<number | null> = {
  key: "Auth_LastNewRefreshTokenTime",
  pathIndependant: true,
  userIndependant: true,
};
const lastRefreshTokenStoreKey: StorageKeyInfo<string> = {
  key: "Auth_LastRefreshToken",
  pathIndependant: true,
  userIndependant: true,
};

const minutesBeforeExpirationToRenew = 30;
const minutesBeforeRefreshTokenExpirationToWarn = 15;
const refreshTokenLifetimeAbsoluteHours = 12;
const refreshTokenLifetimeSlidingHours = 4;

export class AuthenticationService {
  private lastAccessToken: string | null = null;
  private refreshTokenAboutToExpireCallbacks: (() => void)[] = [];
  private refreshTokenAbsoluteExpirationDate: Date | null = null;
  private refreshTokenExpirationTimeout = 0;
  private refreshTokenExpiredCallbacks: (() => void)[] = [];
  private refreshTokenSlidingExpirationDate: Date | null = null;
  private renewSessionTries: RenewSessionTries = { count: 0 };
  private signedOutCallbacks: ((forceLoginPrompt: boolean) => void)[] = [];
  private userLoadedCallbacks: ((user: User) => Promise<void>)[] = [];
  private userManager: UserManagerWithMetadataCache;

  public constructor() {
    const currentUrlOrigin = window.location.origin;

    this.userManager = new UserManagerWithMetadataCache({
      authority: appSettings.B2CUri + "/tfp/b2c_1a_backofficeuser_signin/v2.0",
      automaticSilentRenew: false,
      client_id: appSettings.B2CClientId,
      loadUserInfo: false,
      post_logout_redirect_uri: `${currentUrlOrigin}/signout-callback`,
      redirect_uri: `${currentUrlOrigin}/signin-callback`,
      response_mode: "query",
      response_type: "code",
      scope: appSettings.B2CScope,
      silent_redirect_uri: `${currentUrlOrigin}/silent-callback`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState === "visible") {
        await this.updateFromStorage();
      }
    });
    window.addEventListener("storage", () => this.updateFromStorage());

    this.userManager.events.addUserLoaded((user) => {
      const lastRefreshToken = LocalStorageUtil.get(
        lastRefreshTokenStoreKey,
        null,
      );

      if (lastRefreshToken !== user.refresh_token) {
        LocalStorageUtil.set(
          lastNewRefreshTokenTimeStoreKey,
          new Date().getTime(),
        );
        this.updateRefreshTokenDates();
        LocalStorageUtil.set(lastRefreshTokenStoreKey, user.refresh_token);
      }
    });

    if (typeof window.console !== "undefined") {
      Log.setLogger(window.console);
    }
    Log.setLevel(appSettings.Env === "Local" ? Log.INFO : Log.ERROR);

    this.updateRefreshTokenDates();
  }

  public async getUser(): Promise<User | null> {
    const user = await this.userManager.getUser();
    this.lastAccessToken = user?.access_token ?? null;

    if (user) {
      user.profile = {
        ...user.profile,
        branchId:
          typeof user.profile.branchId === "string"
            ? parseInt(user.profile.branchId)
            : user.profile.branchId,
      };
    }

    return user;
  }

  public off(event: "userLoaded", callback: (user: User) => void): void;
  public off(
    event: "signedOut",
    callback: (forceLoginPrompt: boolean) => void,
  ): void;
  public off(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired",
    callback: () => void,
  ): void;
  public off(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired"
      | "signedOut"
      | "userLoaded",
    callback:
      | (() => void)
      | ((user: User) => void)
      | ((forceLoginPrompt: boolean) => void),
  ): void {
    switch (event) {
      case "refreshTokenAboutToExpire":
        this.refreshTokenAboutToExpireCallbacks =
          this.refreshTokenAboutToExpireCallbacks.filter((c) => c !== callback);
        break;
      case "refreshTokenExpired":
        this.refreshTokenExpiredCallbacks =
          this.refreshTokenExpiredCallbacks.filter((c) => c !== callback);
        break;
      case "sessionExpired":
        this.userManager.events.removeAccessTokenExpired(
          callback as () => void,
        );
        break;
      case "signedOut":
        this.signedOutCallbacks = this.signedOutCallbacks.filter(
          (c) => c !== callback,
        );
        break;
      case "userLoaded":
        this.userLoadedCallbacks = this.userLoadedCallbacks.filter(
          (c) => c !== callback,
        );
        break;
      default:
        throw new Error(`Unknown event ${event as string}`);
    }
  }

  public on(event: "userLoaded", callback: (user: User) => Promise<void>): void;
  public on(
    event: "signedOut",
    callback: (forceLoginPrompt: boolean) => void,
  ): void;
  public on(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired",
    callback: () => void,
  ): void;
  public on(
    event:
      | "refreshTokenAboutToExpire"
      | "refreshTokenExpired"
      | "sessionExpired"
      | "signedOut"
      | "userLoaded",
    callback:
      | (() => void)
      | ((user: User) => Promise<void>)
      | ((forceLoginPrompt: boolean) => void),
  ): void {
    switch (event) {
      case "refreshTokenAboutToExpire":
        this.refreshTokenAboutToExpireCallbacks.push(callback as () => void);
        break;
      case "refreshTokenExpired":
        this.refreshTokenExpiredCallbacks.push(callback as () => void);
        break;
      case "sessionExpired":
        this.userManager.events.addAccessTokenExpired(callback as () => void);
        break;
      case "signedOut":
        this.signedOutCallbacks.push(
          callback as (forceLoginPrompt: boolean) => void,
        );
        break;
      case "userLoaded":
        this.userLoadedCallbacks.push(
          callback as (user: User) => Promise<void>,
        );
        break;
      default:
        throw new Error(`Unknown event ${event as string}`);
    }
  }

  public async renewSession(branchId?: number, cityId?: number): Promise<void> {
    const branch = branchId ?? userBOStore.get()!.branchId;
    const city = cityId ?? userBOStore.get()!.cityId;

    if (
      this.renewSessionTries.lastRenewTime &&
      differenceInSeconds(new Date(), this.renewSessionTries.lastRenewTime) < 30
    ) {
      this.renewSessionTries.count++;
      if (this.renewSessionTries.count > 4) {
        void Logging.warning("Possible renew session loop");
        for (const callback of this.signedOutCallbacks) {
          callback(false);
        }
        return;
      }
    } else {
      this.renewSessionTries.count = 0;
    }

    let user = await this.userManager.getUser();

    if (user != null) {
      const b2cScopeConnectedBranch = `${appSettings.B2CScopeBasePath}ConnectedBranchId_${branch.toString()}`;
      const b2cScopeConnectedCity = `${appSettings.B2CScopeBasePath}ConnectedCityId_${city.toString()}`;

      user.scope =
        user.scope?.replace(/https\S*ConnectedBranchId_[0-9]*/g, "") ?? "";
      user.scope = user.scope.replace(/https\S*ConnectedCityId_[0-9]*/g, "");

      user.scope += ` ${b2cScopeConnectedBranch}`;
      user.scope += ` ${b2cScopeConnectedCity}`;

      await this.userManager.storeUser(user);
    }

    this.renewSessionTries.lastRenewTime = new Date();
    renewingSessionStore.set(true);
    try {
      try {
        user = await this.retryRequestOnError(() =>
          this.userManager.signinSilent(this.getRedirectParams()),
        );
      } catch (ex) {
        throw this.convertUserManagerException(ex);
      }

      if (!user) {
        throw new Error("signinSilent failed");
      } else if (user.access_token !== this.lastAccessToken) {
        for (const callback of this.userLoadedCallbacks) {
          await callback(user);
        }
        this.lastAccessToken = user.access_token;
      }

      if (isLegacyRoute()) {
        await loginToLegacy(true);
      } else {
        expireLegacySessions();
      }
    } finally {
      renewingSessionStore.set(false);
    }
  }

  public shouldRenewSession(user: User): boolean {
    const seconds = minutesBeforeExpirationToRenew * 60;
    return user.expires_in === undefined || user.expires_in < seconds;
  }

  public async signinCallback(): Promise<User> {
    let user: User;
    try {
      user = await this.retryRequestOnError(() =>
        this.userManager.signinRedirectCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }

    await this.userManager.clearStaleState();

    // Fix for Identity Server returning multiple values in auth_time
    if (Array.isArray(user.profile.auth_time)) {
      user.profile.auth_time = user.profile.auth_time[0] as number;
      await this.userManager.storeUser(user);
    }

    if (
      !this.refreshTokenAbsoluteExpirationDate ||
      this.refreshTokenAbsoluteExpirationDate < new Date() ||
      (this.refreshTokenSlidingExpirationDate &&
        this.refreshTokenSlidingExpirationDate < new Date())
    ) {
      LocalStorageUtil.set(lastLoginTimeStoreKey, new Date().getTime());
      this.updateRefreshTokenDates();
    }

    return user;
  }

  public async signinRedirect(
    localeId?: string,
    url?: string,
    forceLoginPrompt?: boolean,
  ): Promise<void> {
    if (forceLoginPrompt) {
      LocalStorageUtil.remove(lastLoginTimeStoreKey);
    }

    const params = this.getRedirectParams(localeId, url);

    if (forceLoginPrompt) {
      params.prompt = "login";
    }

    try {
      await this.retryRequestOnError(() =>
        this.userManager.signinRedirect(params),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async signoutCallback(): Promise<SignoutResponse> {
    try {
      return await this.retryRequestOnError(() =>
        this.userManager.signoutRedirectCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async signoutRedirect(): Promise<void> {
    LocalStorageUtil.remove(lastLoginTimeStoreKey);

    try {
      await this.retryRequestOnError(() =>
        this.userManager.signoutRedirect(this.getRedirectParams()),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async silentCallback(): Promise<void> {
    try {
      await this.retryRequestOnError(() =>
        this.userManager.signinSilentCallback(),
      );
    } catch (ex) {
      throw this.convertUserManagerException(ex);
    }
  }

  public async switchBranch(
    newBranchId: number,
    newCityId: number,
  ): Promise<void> {
    await this.renewSession(newBranchId, newCityId);

    const user = await this.userManager.getUser();
    if (user != null) {
      user.profile.branchId = newBranchId;
      await this.userManager.storeUser(user);
    }

    window.location.reload();
  }

  private convertUserManagerException(exception: unknown): unknown {
    const fetchErrors = ["Failed to fetch", "Load failed", "NetworkError"];
    if (
      exception instanceof Error &&
      fetchErrors.some((e) => exception.message.startsWith(e))
    ) {
      return new NetworkRequestFailedError();
    }

    return exception;
  }

  private getRedirectParams(
    localeId?: string,
    state?: string,
  ): Record<string, unknown> {
    return {
      extraQueryParams: {
        isStaging: "False", // TODO AzureB2C - provide a value here
      },
      isStaging: "False", // TODO AzureB2C - provide a value here
      state: state ?? window.location.pathname + window.location.search,
      ui_locales: localeId ?? languageStore.get().localeId,
    };
  }

  private async retryRequestOnError<T>(request: () => Promise<T>): Promise<T> {
    const retryDelaySeconds = 5;
    try {
      return await request();
    } catch {
      return new Promise<T>((resolve, reject) => {
        window.setTimeout(async () => {
          try {
            resolve(await request());
          } catch (error) {
            reject(error);
          }
        }, retryDelaySeconds * 1000);
      });
    }
  }

  private setRefreshTokenExpirationTimeout(): void {
    window.clearTimeout(this.refreshTokenExpirationTimeout);

    if (
      !this.refreshTokenSlidingExpirationDate ||
      !this.refreshTokenAbsoluteExpirationDate
    ) {
      return;
    }

    const refreshTokenExpirationTime = Math.min(
      this.refreshTokenSlidingExpirationDate.getTime(),
      this.refreshTokenAbsoluteExpirationDate.getTime(),
    );
    const timeoutMilliseconds =
      refreshTokenExpirationTime - new Date().getTime();
    const millisecondsBeforeToWarn = minutesToMilliseconds(
      minutesBeforeRefreshTokenExpirationToWarn,
    );

    if (timeoutMilliseconds <= 0) {
      for (const callback of this.refreshTokenExpiredCallbacks) {
        callback();
      }
    } else if (timeoutMilliseconds > millisecondsBeforeToWarn) {
      this.refreshTokenExpirationTimeout = window.setTimeout(() => {
        for (const callback of this.refreshTokenAboutToExpireCallbacks) {
          callback();
        }
        this.setRefreshTokenExpirationTimeout();
      }, timeoutMilliseconds - millisecondsBeforeToWarn);
    } else {
      this.refreshTokenExpirationTimeout = window.setTimeout(() => {
        for (const callback of this.refreshTokenExpiredCallbacks) {
          callback();
        }
      }, timeoutMilliseconds);
    }
  }

  private async updateFromStorage(): Promise<void> {
    const user = await this.userManager.getUser();
    if (!user) {
      if (this.lastAccessToken !== null) {
        await this.userManager.removeUser(); // Unbind events

        for (const callback of this.signedOutCallbacks) {
          callback(true);
        }
        this.lastAccessToken = null;
      }
    } else {
      if (user.access_token !== this.lastAccessToken) {
        for (const callback of this.userLoadedCallbacks) {
          await callback(user);
        }
        this.lastAccessToken = user.access_token;
      }
    }

    this.updateRefreshTokenDates();
  }

  private updateRefreshTokenDates(): void {
    const lastLoginTime = LocalStorageUtil.get(lastLoginTimeStoreKey, null);
    const lastLoginDate = lastLoginTime ? new Date(lastLoginTime) : undefined;
    if (lastLoginDate) {
      this.refreshTokenAbsoluteExpirationDate = addDate(lastLoginDate, {
        hours: refreshTokenLifetimeAbsoluteHours,
      });
    } else {
      this.refreshTokenAbsoluteExpirationDate = null;
    }

    const lastNewRefreshTokenTime = LocalStorageUtil.get(
      lastNewRefreshTokenTimeStoreKey,
      null,
    );
    const lastNewRefreshTokenDate = lastNewRefreshTokenTime
      ? new Date(lastNewRefreshTokenTime)
      : undefined;

    if (lastNewRefreshTokenDate) {
      this.refreshTokenSlidingExpirationDate = addDate(
        lastNewRefreshTokenDate,
        { hours: refreshTokenLifetimeSlidingHours },
      );
    } else {
      this.refreshTokenSlidingExpirationDate = null;
    }

    this.setRefreshTokenExpirationTimeout();
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
