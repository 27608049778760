import LocalStorageUtil, {
  StorageKeyInfo,
} from "@reservauto/react-shared/localStorage/LocalStorageUtil";
import AuthenticatedServiceBase from "@reservauto/react-shared/services/AuthenticatedServiceBase";
import { FetchPromise } from "@reservauto/react-shared/services/ServiceBase";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import appSettings from "../../shared/appSettings";
import branchStore from "../../shared/stores/branchStore";
import { LegacyTypes } from "./legacyRoute";

type IsAuthenticated = Partial<Record<LegacyTypes, boolean>>;

export interface LegacyAuthenticateResponse {
  d?: { Success: boolean };
}

export interface MvcAuthenticateResponse {
  success: boolean;
}

const iframeIsAuthenticatedStoreKey: StorageKeyInfo<IsAuthenticated> = {
  key: "IframeIsAuthenticated",
  pathIndependant: true,
};

export class LegacyIsAuthenticatedStore extends StateStoreBase<IsAuthenticated> {
  private isAuthenticated: IsAuthenticated | null = null;

  public get(): IsAuthenticated {
    if (!this.isAuthenticated) {
      this.isAuthenticated = LocalStorageUtil.get(
        iframeIsAuthenticatedStoreKey,
        {},
      );
    }

    return this.isAuthenticated;
  }

  public set(isAuthenticated: IsAuthenticated): void {
    this.isAuthenticated = { ...this.isAuthenticated, ...isAuthenticated };
    LocalStorageUtil.set(iframeIsAuthenticatedStoreKey, this.isAuthenticated);
    this.notifySubscribers();
  }
}

const legacyIsAuthenticatedStore = new LegacyIsAuthenticatedStore();
export { legacyIsAuthenticatedStore };

export function expireLegacySessions(): void {
  new MvcAuthenticationService().loginExpired();
  new LegacyAuthenticationService().loginExpired();
}

export function loginToLegacy(force?: boolean): FetchPromise<void> {
  const mvcAuthService = new MvcAuthenticationService();
  const legacyAuthService = new LegacyAuthenticationService();

  if (force) {
    mvcAuthService.loginExpired();
    legacyAuthService.loginExpired();
  }

  const mvcRequest = mvcAuthService.login();
  const legacyRequest = legacyAuthService.login();

  const promise = new FetchPromise<void>(async (resolve, reject) => {
    try {
      const [mvcResult, legacyResult] = await Promise.all([
        mvcRequest,
        legacyRequest,
      ]);

      if (!mvcResult.success) {
        reject(new Error("MVC signin failed"));
      } else if (!legacyResult.d?.Success) {
        reject(new Error("Legacy signin failed"));
      } else {
        resolve();
      }
    } catch (ex) {
      reject(ex);
    }
  });

  promise.abort = (): void => {
    mvcRequest.abort();
    legacyRequest.abort();
  };

  return promise;
}

export class LegacyAuthenticationService extends AuthenticatedServiceBase {
  protected baseUrl = "WCF/Reservauto/Authentication/AuthenticationService.svc";
  protected get rootUrl(): string {
    return branchStore.get().reservautoLegacyUri;
  }

  public login(): FetchPromise<LegacyAuthenticateResponse> {
    if (legacyIsAuthenticatedStore.get().legacy) {
      return new FetchPromise((resolve) => resolve({ d: { Success: true } }));
    }

    return this.authorizedPost<LegacyAuthenticateResponse, unknown>({
      isRetrySafe: true,
      url: "ReservautoLogin",
    }).then((result) => {
      if (result.d?.Success) {
        legacyIsAuthenticatedStore.set({ legacy: true });
      }
      return result;
    });
  }

  public loginExpired(): void {
    legacyIsAuthenticatedStore.set({ legacy: false });
  }

  public logout(): Promise<void> {
    if (legacyIsAuthenticatedStore.get().legacy) {
      legacyIsAuthenticatedStore.set({ legacy: false });

      return this.authorizedPost({ isRetrySafe: true, url: "Logout" });
    }
    return new Promise((resolve) => resolve());
  }
}

export class MvcAuthenticationService extends AuthenticatedServiceBase {
  protected baseUrl = "Auth";
  protected get rootUrl(): string {
    return appSettings.CommunautoReservautoMVCUri;
  }

  public login(): FetchPromise<MvcAuthenticateResponse> {
    if (legacyIsAuthenticatedStore.get().mvc) {
      return new FetchPromise((resolve) => resolve({ success: true }));
    }

    return this.authorizedPost<MvcAuthenticateResponse, unknown>({
      isRetrySafe: true,
      url: "LoginOAuth",
    }).then((result) => {
      if (result.success) {
        legacyIsAuthenticatedStore.set({ mvc: true });
      }
      return result;
    });
  }

  public loginExpired(): void {
    legacyIsAuthenticatedStore.set({ mvc: false });
  }

  public logout(): Promise<void> {
    if (legacyIsAuthenticatedStore.get().mvc) {
      legacyIsAuthenticatedStore.set({ mvc: false });

      return this.authorizedPost({ isRetrySafe: true, url: "LogoutOAuth" });
    }
    return new Promise((resolve) => resolve());
  }
}
